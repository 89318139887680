<template>
  <div class="glossary">
    <el-form
      ref="form"
      class="filters"
      @submit.native="setFilters">
      <el-form-item
        :label="$t('filters.orderby')"
        class="filters__orderby">
        <el-select
          v-model="selectedOrder"
          :placeholder="$t('filters.orderby')"
          @change="setFilters">
          <el-option
            v-for="item in orderbyItems"
            :key="item.value"
            :label="$t(`filters.${item.label}`)"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="filters__search">
        <el-input
          class="filters__search-input"
          :placeholder="$t('filters.search')"
          v-model="searchTerm"
          @keyup.enter.native="setFilters"
          @clear="setFilters"
          clearable>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-form-item>
    </el-form>
    <Accordion
      :items="items"
      :isLoading="isLoading"
      :isEmptyResponse="isEmptyResponse">
    </Accordion>
    <el-pagination
      background
      @current-change="handleCurrentPageChange"
      :hide-on-single-page="true"
      :current-page.sync="currentPage"
      :page-size="perPage"
      :total="itemsTotal"
      layout="prev, pager, next">
    </el-pagination>
  </div>
</template>

<script>
import orderbyItems from '@/config/orderbyItems';
import { glossaryApi } from '@/api';
import Accordion from '@/components/Accordion.vue';

export default {
  name: 'Glossary',
  data() {
    return {
      items: [],
      itemsTotal: 0,
      pagesTotal: 0,
      perPage: 6, // Default page post limit
      currentPage: 1,
      isLoading: false,
      isEmptyResponse: false,
      selectedOrder: 'date-desc',
      orderbyItems,
      searchTerm: '',
      order: '',
      orderby: '',
    };
  },
  components: {
    Accordion,
  },
  created() {
    this.parseQueryFilters(this.$route.query);
    this.getRemoteItems();
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryFilters(to.query);
    this.getRemoteItems();
    next();
  },
  methods: {
    async getRemoteItems() {
      try {
        this.isLoading = true;
        const params = {
          per_page: this.perPage,
          page: this.currentPage,
          order: this.order,
          orderby: this.orderby,
          search: this.searchTerm,
        };
        const response = await glossaryApi.getGlossaryTerms(params);
        this.isLoading = false;
        this.items = response.data;
        this.itemsTotal = parseInt(response.headers['x-wp-total'], 10);
        this.pagesTotal = parseInt(response.headers['x-wp-totalpages'], 10);
        this.isEmptyResponse = (this.items.length === 0);
      } catch (error) {
        this.isLoading = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
    handleCurrentPageChange(page) {
      this.$router.push({
        name: 'Glossary',
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    parseQueryFilters(query) {
      this.searchTerm = query.search || '';
      this.currentPage = query.page ? parseInt(query.page, 10) : 1;
      this.selectedOrder = query.order || 'date-desc';

      const orderValue = this.orderbyItems.find((item) => item.value === this.selectedOrder);
      this.order = orderValue.params.order;
      this.orderby = orderValue.params.orderby;
    },
    setFilters() {
      this.$router.push({
        name: 'Glossary',
        query: {
          search: this.searchTerm,
          order: this.selectedOrder,
          page: 1,
        },
      }).catch(() => {}); // Fixes a bug in vue router same page navigation
    },
  },
};
</script>
